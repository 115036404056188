import PortableText from "components/PortableText"
import { getHref } from "lib/helpers"
import { urlForImage } from "lib/sanity.image"
import Link from "next/link"
import { VerticalCardsItem } from "schemas"
import { Center, styled, VStack } from "styled-system/jsx"
import { hstack, square } from "styled-system/patterns"

type Props = VerticalCardsItem

function VerticalCardsItem({ title, content, link, cta, image }: Props) {
  return (
    <VStack
      gap={0}
      css={{
        width: "100%",
        maxWidth: "450px",
        p: "12px 20px 40px",
        bg: "white",
        rounded: "20px",
        filter: "drop-shadow(0px 7px 24px rgba(0, 0, 0, 0.10))",
        color: "other.1",
        textAlign: "center",
      }}>
      {image?.asset && (
        <img
          alt={image.alt || ""}
          src={urlForImage(image).width(400).url()}
          className={square({
            mx: "auto",
          })}
        />
      )}

      <styled.h3
        css={{
          mt: "6px",
          color: "#000A3A",
          textAlign: "center",
          fontFamily: "heading",
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "150%",
          letterSpacing: "-0.38px",
        }}>
        {title}
      </styled.h3>

      <styled.div
        css={{
          mt: "30px",
          mb: "40px",
        }}>
        <PortableText className={"x"} value={content} />
      </styled.div>

      <Center mt="auto">
        {cta?.url && (
          <styled.a
            as={Link}
            href={getHref(cta)}
            className={hstack({
              gap: 2,
              mx: "auto",
              color: "primary.4",
              fontWeight: 600,
              lineHeight: "150%",
              textTransform: "uppercase",
            })}>
            {cta.text}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none">
              <path
                d="M1.88325 0.649658C1.56485 0.649925 1.2537 0.744755 0.989269 0.922117C0.72484 1.09948 0.519046 1.35138 0.397992 1.64588C0.276937 1.94037 0.246077 2.26418 0.309326 2.57624C0.372574 2.8883 0.527081 3.17454 0.753249 3.39866L2.78364 5.40779C2.8619 5.48537 2.92402 5.57769 2.96642 5.67941C3.00881 5.78113 3.03064 5.89023 3.03064 6.00043C3.03064 6.11063 3.00881 6.21974 2.96642 6.32146C2.92402 6.42318 2.8619 6.51549 2.78364 6.59307L0.753249 8.6022C-0.267262 9.61102 0.448159 11.3501 1.88325 11.3501C2.30675 11.3504 2.71322 11.1834 3.01432 10.8856L6.79659 7.1416C6.94765 6.99231 7.06758 6.81452 7.14944 6.61854C7.23129 6.42256 7.27344 6.21229 7.27344 5.9999C7.27344 5.78752 7.23129 5.57724 7.14944 5.38126C7.06758 5.18528 6.94765 5.0075 6.79659 4.8582L3.01432 1.1142C2.71322 0.816393 2.30675 0.649451 1.88325 0.649658Z"
                fill="url(#paint0_linear_1_378)"
              />
              <path
                d="M1.88325 0.649658C1.56485 0.649925 1.2537 0.744755 0.989269 0.922117C0.72484 1.09948 0.519046 1.35138 0.397992 1.64588C0.276937 1.94037 0.246077 2.26418 0.309326 2.57624C0.372574 2.8883 0.527081 3.17454 0.753249 3.39866L2.78364 5.40779C2.8619 5.48537 2.92402 5.57769 2.96642 5.67941C3.00881 5.78113 3.03064 5.89023 3.03064 6.00043C3.03064 6.11063 3.00881 6.21974 2.96642 6.32146C2.92402 6.42318 2.8619 6.51549 2.78364 6.59307L0.753249 8.6022C-0.267262 9.61102 0.448159 11.3501 1.88325 11.3501C2.30675 11.3504 2.71322 11.1834 3.01432 10.8856L6.79659 7.1416C6.94765 6.99231 7.06758 6.81452 7.14944 6.61854C7.23129 6.42256 7.27344 6.21229 7.27344 5.9999C7.27344 5.78752 7.23129 5.57724 7.14944 5.38126C7.06758 5.18528 6.94765 5.0075 6.79659 4.8582L3.01432 1.1142C2.71322 0.816393 2.30675 0.649451 1.88325 0.649658Z"
                fill="#8C60FA"
              />
              <path
                d="M1.88325 0.649658C1.56485 0.649925 1.2537 0.744755 0.989269 0.922117C0.72484 1.09948 0.519046 1.35138 0.397992 1.64588C0.276937 1.94037 0.246077 2.26418 0.309326 2.57624C0.372574 2.8883 0.527081 3.17454 0.753249 3.39866L2.78364 5.40779C2.8619 5.48537 2.92402 5.57769 2.96642 5.67941C3.00881 5.78113 3.03064 5.89023 3.03064 6.00043C3.03064 6.11063 3.00881 6.21974 2.96642 6.32146C2.92402 6.42318 2.8619 6.51549 2.78364 6.59307L0.753249 8.6022C-0.267262 9.61102 0.448159 11.3501 1.88325 11.3501C2.30675 11.3504 2.71322 11.1834 3.01432 10.8856L6.79659 7.1416C6.94765 6.99231 7.06758 6.81452 7.14944 6.61854C7.23129 6.42256 7.27344 6.21229 7.27344 5.9999C7.27344 5.78752 7.23129 5.57724 7.14944 5.38126C7.06758 5.18528 6.94765 5.0075 6.79659 4.8582L3.01432 1.1142C2.71322 0.816393 2.30675 0.649451 1.88325 0.649658Z"
                fill="#8C60FA"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1_378"
                  x1="0.273152"
                  y1="5.9999"
                  x2="7.27344"
                  y2="5.99988"
                  gradientUnits="userSpaceOnUse">
                  <stop stopColor="#8C60FA" />
                  <stop offset="0.206916" stopColor="#8E69FB" />
                  <stop offset="0.4388" stopColor="#9482FC" />
                  <stop offset="0.891867" stopColor="#9EAAFF" />
                  <stop offset="0.997108" stopColor="#9FAFFF" />
                </linearGradient>
              </defs>
            </svg>
          </styled.a>
        )}
      </Center>
    </VStack>
  )
}

export default VerticalCardsItem
export type { Props as VerticalCardsItemProps }
